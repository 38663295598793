import useQuery from "components/useQuery";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOnBoarding } from "redux/features/userSlice";

const onBoradingdata = [
  {
    id: 5,
    image: "5",
    title: `Bienvenue sur
    Genimi`,
    description: `L’application qui aide les parents
    d'élèves pour les devoirs à
    la maison.`,
  },
  {
    id: 1,
    image: "1",
    title: "Ceci est une démo",
    description:
      "Vous avez 100 crédits pour vous familiariser avec l’application. ",
  },
  {
    id: 2,
    image: "2",
    title: `Connaissez vous
    réellement le niveau de
    votre enfant ?`,
    description: `Pour aider efficacement votre enfant, il est important d’identifier ses lacunes et faire le diagnostic en 15 minutes.`,
  },
  {
    id: 3,
    image: "3",
    title: `Trouvez un endroit
    calme`,
    description: `Évitez toutes sortes de distractions pour rester dans le temps.`,
  },
  {
    id: 4,
    image: "4",
    title: `Pour faire un diagnostique complet et accéder à toutes les fonctionnalités `,
    description: "Contactez-nous.",
  },

  //   { id: 4, image: "", title: "", description: "" },
];

function OnBoarding() {
  const navigate = useNavigate();

  const query = useQuery();

  const carouselRef = useRef(null);

  //   console.log({ query: query?.get("active") });
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(
    query?.get("active") ? parseInt(query?.get("active")) : 0
  );

  const onEndOnBoarding = () => {
    dispatch(setOnBoarding());
    navigate("/", { replace: true });
  };

  // const scrollToIndex = (index) => {
  //   if (carouselRef.current) {
  //     const itemWidth = carouselRef.current.offsetWidth; // Largeur d'un élément dans le carrousel
  //     carouselRef.current.scrollLeft = itemWidth * index; // Défilement jusqu'à l'élément spécifié
  //     console.log({ index });
  //     setActiveIndex(index);
  //   }
  // };
  const scrollToIndex = (index) => {
    const item = carouselRef.current.children[index];
    if (item) {
      const itemWidth = carouselRef.current.offsetWidth;
      // item.scrollIntoView({ behavior: "smooth", block: "end" }); // Défilement vers l'élément spécifié
      carouselRef.current.scrollLeft = itemWidth * index;
      setActiveIndex(index);
    }
  };

  // Appeler scrollToIndex lorsque le bouton est cliqué
  const handleButtonClick = (index) => {
    scrollToIndex(index);
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = carouselRef.current;
      const scrollLeft = container.scrollLeft;
      const containerWidth = container.offsetWidth;
      const currentIndex = Math.floor(scrollLeft / containerWidth);
      const nextIndex = currentIndex + 1;
      const maxIndex = onBoradingdata.length - 1;

      const distanceToNextItem = (nextIndex + 1) * containerWidth - scrollLeft;
      if (distanceToNextItem <= containerWidth / 2 && nextIndex <= maxIndex) {
        scrollToIndex(nextIndex);
      }
    };

    const container = carouselRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="h-[100dvh] bg-[#fff] justify-between overflow-y-auto flex-1 flex scroll-smooth duration-500"
      ref={carouselRef}
    >
      {onBoradingdata?.map((card, _index) => (
        <div
          className=" min-w-full min-h-[100dvh] flex flex-col"
          key={card?.id}
        >
          <div className="flex items-center justify-end p-2">
            <p
              className="text-[#2A65E5] py-2 cursor-pointer text-lg"
              onClick={onEndOnBoarding}
            >
              sauter
            </p>
          </div>
          <div className="flex flex-1 flex-col gap-8 justify-between pb-4">
            <div className="max-h-[250px] flex justify-center">
              <img
                src={`./assets/onboardings/${card?.image}.png`}
                alt="onboarding "
                className="p-2 h-full"
              />
            </div>

            <div className="flex items-center justify-center gap-4">
              {onBoradingdata.map((item, index) => (
                <div
                  className="w-[12px] h-[12px] rounded-full border-[#000] border-2"
                  style={{
                    backgroundColor:
                      activeIndex === index ? "#000" : "transparent",
                  }}
                  key={item?.id}
                ></div>
              ))}
            </div>

            <div className="flex flex-col gap-4 items-center p-3">
              <p className="text-center text-[28px] font-[500]">
                {card?.title}
              </p>

              <p className="text-center text-[16px]">{card?.description}</p>
            </div>

            <div
              className="flex items-center justify-center cursor-pointer gap-5 text-white p-2 mx-4 bg-[#2A65E5] rounded-full"
              onClick={() =>
                _index + 1 === onBoradingdata?.length
                  ? onEndOnBoarding()
                  : handleButtonClick(_index + 1)
              }
              data-testid="cypress-welcom-button"
            >
              {/* {activeIndex + 1 === onBoradingdata.length
                ? "Commencer"
                : "Suivant"} */}
              {_index + 1 === onBoradingdata.length ? "Commencer" : "Suivant"}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default OnBoarding;
