import Button from "components/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Check } from "tabler-icons-react";

function ContactGenimiSuccessPage() {
  const navigation = useNavigate();
  return (
    <div className="flex items-center justify-center flex-col h-screen gap-6 p-4">
      <div className=" p-4 rounded-full bg-[green] shadow-lg">
        <Check color="#fff" size={25} />
      </div>

      <h1 className="text-lg font-400 text-center p-4">
        Le formulaire a été envoyé avec succès ! <br />
        Vous serez contacté par l’équipe Génimi d’ici peu.
      </h1>

      <Button
        radius={"md"}
        style={{
          padding: "10px 20px",
          width: "100%",
          backgroundColor: "#003B7F",
        }}
        onClick={() => {
          navigation(-1);
          navigation(-1);
          navigation(-1);
        }}
      >
        Voir les résultats
      </Button>
    </div>
  );
}

export default ContactGenimiSuccessPage;
