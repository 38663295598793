import HistoriqueDetail from "pages/home/components/historique/detail";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetDiagnostiqueTentavivesByElevesGroupeByTententativeDetailsQuery } from "redux/features/diagnostiqueApi";

function DetailHistoriquePage() {
  const params = useParams();

  const { data, isLoading } =
    useGetDiagnostiqueTentavivesByElevesGroupeByTententativeDetailsQuery(
      parseInt(params?.tentativeId)
    );

  return (
    <HistoriqueDetail data={data ? data?.data : []} isLoading={isLoading} />
  );
}

export default DetailHistoriquePage;
