import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Message, Phone, PhoneCall } from "tabler-icons-react";

export default function CallToactionBtn() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="z-50">
      {show ? (
        <div className="fixed bottom-[90px] right-6 w-auto py-2 px-4 rounded-lg bg-white flex flex-col gap-3 shadow-lg border border-[#9993]">
          <div
            onClick={() => navigate("/contact-genimi")}
            className="flex items-center gap-2 cursor-pointer hover:bg-[#9992]"
          >
            <Message />
            <span className="">Message</span>
          </div>
          <a
            href="tel:+221703315690"
            className="flex items-center gap-2 cursor-pointer hover:bg-[#9992]"
          >
            <Phone />
            <span className="">Telephone</span>
          </a>
        </div>
      ) : null}
      <div
        onClick={() => setShow(!show)}
        className="w-14 h-14 flex items-center justify-center rounded-full bg-[#2A65E5] hover:bg-[#2a46e5] text-white cursor-pointer fixed bottom-4 right-4"
      >
        <PhoneCall />
      </div>
    </div>
  );
}
