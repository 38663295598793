import { useSelector } from "react-redux";
import { getParentForm } from "redux/features/parentSlice";
// import { Logout } from "tabler-icons-react";

const Credit = () => {
  // const { logout } = useContext(AuthContext);

  const parent = useSelector(getParentForm);
  // const credit = useSelector(getParentCredit)?.find(
  //   (item) => item?.phone === parent?.phone
  // );
  return parent?.credits?.toString() ? (
    <div className="font-bold bg-[green] text-[#fff] px-3 rounded-full text-md">
      <p className="scale-90">{`Credit: ${parent?.credits}`}</p>
    </div>
  ) : null;
};

export default Credit;
