import PageHeader from "components/PageHeader";
import useGetUserInfo from "hooks/useGetUserInfo";
import { Template } from "layouts/Template";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetElevesByParentQuery } from "redux/features/eleveApi";
import { Search } from "tabler-icons-react";
import EleveCard from "pages/home/components/EleveCard";
import RenderData from "components/RenderData";

function ElevePage() {
  const navigation = useNavigate();
  const { userId } = useGetUserInfo();

  const { data, isLoading } = useGetElevesByParentQuery(parseInt(userId));

  // console.log(data);

  const [query, setQuery] = useState("");

  return (
    <Template
      header={<PageHeader title={"Liste de mes enfants"} />}
      style={{
        backgroundColor: "rgba(0,0,0,0.05)",
        padding: 10,
      }}
    >
      <div
        className=""
        style={{
          backgroundColor: "#fff",
          borderRadius: 20,
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          marginTop: 20,
        }}
      >
        <input
          type="text"
          placeholder="Rechercher un de vos enfants par nom | prenom | classe | ecole"
          className="px-6 text-xs"
          style={{
            flex: 1,
            height: "100%",
            outline: "none",
            border: "none",
          }}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="" style={{ padding: 10, color: "gray" }}>
          <Search size={16} />
        </div>
      </div>

      <div className="" style={{ marginTop: 29 }}>
        <RenderData
          isLoading={isLoading}
          data={
            data?.data?.filter(
              (item) =>
                item?.firstName.toLowerCase().includes(query.toLowerCase()) ||
                item?.lastName.toLowerCase().includes(query.toLowerCase()) ||
                item?.classe?.name
                  .toLowerCase()
                  .includes(query.toLowerCase()) ||
                item?.school?.name.toLowerCase().includes(query.toLowerCase())
            ) ?? []
          }
          render={(item) => (
            <EleveCard
              onClick={() =>
                navigation("/" + item?.id + "/matiere", {
                  state: {
                    username: `${item?.firstName} ${item?.lastName}`,
                    id: item?.id,
                    classe: {
                      id: item?.classe?.id,
                      name: item?.classe?.name,
                    },
                    school: item?.school?.name,
                  },
                })
              }
              {...item}
              key={item?.id}
            />
          )}
        />
      </div>
    </Template>
  );
}

export default ElevePage;
