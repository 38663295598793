import HistoriqueItems from "pages/home/components/historique";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery } from "redux/features/diagnostiqueApi";

function HistoriquePage() {
  const params = useParams();
  const navigation = useNavigate();
  const location = useLocation();

  const { data, isLoading } =
    useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery({
      eleveId: parseInt(params?.eleveId),
      diagnosticId: parseInt(params?.id),
    });

  return (
    <div>
      <div className="mb-2">
        <span className="font-bold text-md">
          {"Historique des tentatives en " +
            location?.state?.name.toLowerCase()}
        </span>
      </div>
      <HistoriqueItems
        isLoading={isLoading}
        data={data ? data?.data : []}
        onClick={(id) => navigation(`../detail/${id}`)}
      />
    </div>
  );
}

export default HistoriquePage;
