import React, { useEffect } from "react";
import { Template } from "layouts/Template";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "tabler-icons-react";
import { useDispatch } from "react-redux";
import { setStudent } from "redux/features/userSlice";
import { useSelector } from "react-redux";
import {
  clearParentCredit,
  clearParentForm,
  getParentCredit,
  getParentForm,
  getParentResetStatus,
  setResetParent,
} from "redux/features/parentSlice";
import { isArray } from "lodash";
import useGetAssurance from "hooks/useGetAssurance";
import useGetParent from "hooks/useGetParent";

const Home = () => {
  const navigation = useNavigate();

  const dispatch = useDispatch();

  useGetParent();

  const credit = useSelector(getParentCredit);
  const getAssrence = useGetAssurance();

  // const { loggedOut } = useGetUserInfo();

  const parents = useSelector(getParentForm);
  const parentResetStatus = useSelector(getParentResetStatus);

  useEffect(() => {
    if (parentResetStatus) {
      // console.log("resset");
      dispatch(clearParentForm());
      dispatch(clearParentCredit());
      dispatch(setResetParent());
      return;
    }
    // console.log("already reset");
  }, [dispatch, parentResetStatus]);

  useEffect(() => {
    // if (!loggedOut) {
    //   console.log("reinitialisation");
    //   // alert("reinitialisation");
    //   // localStorage.removeItem('persist:genimi-parent')
    //   dispatch(setLoggedOut());
    //   dispatch(clearUserInfo());
    //   dispatch(clearAppelOffline());
    //   dispatch(clearParentForm());
    //   dispatch(clearParentCredit());
    //   dispatch(setResetParent());
    //   dispatch(baseApi.util.resetApiState());
    // }
  }, []);

  return (
    <Template
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItem: "center",
        flexDirection: "column",
        height: "100dvh",
        backgroundImage: "url('../../diagnostique-bg.png')",
        // backgroundColor: "red",
        // padding: 20,
        width: "100%",
      }}
    >
      <div className="flex items-center justify-center gap-10 p-4">
        <img src="../../icons/icon1.png" style={{ width: 60 }} alt="logo" />
        {getAssrence === "Senassurance" ? (
          <img
            src="../../icons/senassurance.png"
            style={{ width: 70 }}
            alt="logo"
          />
        ) : null}
      </div>

      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 60,
        }}
      >
        <img
          src="../../diagnostique-acceuil.png"
          style={{ width: "100%" }}
          alt="parent"
        />
      </div>
      <div
        className="mx-3"
        style={{
          height: 270,
          borderRadius: 40,
          backgroundColor: "#fff",
          position: "relative",
          padding: 30,
        }}
      >
        <div className="">
          <h2
            style={{
              color: "#003880",
              textAlign: "center",
              marginBottom: 20,
              fontSize: 25,
            }}
            data-testid="cypress-welcom-title"
          >
            Bienvenue sur Genimi app
          </h2>
          <p
            data-testid="cypress-welcom-description"
            style={{ color: "#575757", textAlign: "center", fontSize: 15 }}
          >
            1ʳᵉ plateforme qui vous permet de détecter facilement les lacunes de
            vos enfants en lecture et en mathématique.
          </p>
        </div>
      </div>
      <div
        className="flex items-center justify-center cursor-pointer gap-5 text-white p-3 my-4 bg-[#77B8FE] rounded-full mx-3"
        onClick={() => {
          if (parents?.phone) {
            // alert("true");
            navigation("/parentProspect/select-enfant");
          } else {
            // alert("false");
            navigation("/parentProspect");
          }
          dispatch(setStudent());
          if (!isArray(credit)) {
            dispatch(clearParentCredit());
          }
        }}
        data-testid="cypress-welcom-button"
      >
        Commencer la Demo
        <ArrowRight color="#fff" />
      </div>
    </Template>
  );
};

export default Home;
