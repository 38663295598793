import { Template } from "layouts/Template";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCurrentEleve } from "redux/features/eleveSlice";
import { ArrowLeft, User } from "tabler-icons-react";

function ComparaisonPage() {
  const params = useParams();
  const navigation = useNavigate();

  //   const [activeLevel, setActiveLevel] = useState(1);

  // const { data: eleve } = useGetEleveQuery(params?.eleveId);

  const studentDemo = useSelector(getCurrentEleve);

  // const { data } =
  //   useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery({
  //     eleveId: parseInt(params?.eleveId),
  //     diagnosticId: parseInt(params?.id),
  //   });

  // useEffect(() => {
  //   console.log({ data });
  // }, []);

  return (
    <Template
      header={
        <div className="flex justify-between items-center px-1 py-3">
          <div className="flex gap-2 items-center">
            <div
              className="cursor-pointer flex items-center justify-center px-1"
              onClick={() => navigation(-1)}
            >
              <ArrowLeft />
            </div>
            <div className="h-8 w-8 bg-primary rounded-full flex justify-center items-center">
              <User color="#fff" size={18} />
            </div>
            <div className="text-md">
              <p className="-mb-1 font-bold">
                {/* {eleve?.firstName} {eleve?.lastName} */}
                {studentDemo?.fullName}
              </p>
              <p className="text-xs tew">{studentDemo?.classe}</p>
            </div>
          </div>
          <h2
            className="text-center px-3 py-1 rounded-xl text-[10px]"
            style={{ backgroundColor: "rgba(0,0,0,.1)" }}
          >
            Diagnostic {params?.name}
          </h2>
        </div>
      }
      style={{
        backgroundColor: "rgba(0,0,0,0.05)",
      }}
    >
      {/* La page de comparaison */}
      <div className="flex columns-2 h-full">
        <div className="w-[150px] p-2 h-full">partie 1</div>
        <div className="flex-1 h-full flex flex-nowrap overflow-auto gap-[1px]">
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <div key={item} className="p-2 bg-white" style={{ minWidth: 100 }}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </Template>
  );
}

export default ComparaisonPage;
