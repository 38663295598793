import Button from "components/Button";
import RenderData from "components/RenderData";
import { Template } from "layouts/Template";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCurrentEleve } from "redux/features/eleveSlice";
import {
  useContactParentMutation,
  useUpdateParentStatusMutation,
} from "redux/features/parentApi";
import { getParentForm } from "redux/features/parentSlice";
import { ArrowLeft, User } from "tabler-icons-react";

function ContactGenimiPage() {
  const params = useParams();
  const navigation = useNavigate();

  const parentForm = useSelector(getParentForm);
  const studentDemo = useSelector(getCurrentEleve);

  const [UPDATEPARENTSTATUST] = useUpdateParentStatusMutation();
  const [CONTACTPARENT] = useContactParentMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    defaultValues: {
      fullName: parentForm?.fullName,
      phone: parentForm?.phone,
    },
  });

  /**
   * Convertir le parent s'il est un TESTEUR et envoyer la description
   * @param {*} data
   * @returns
   */
  const onSubmit = async (data) => {
    const formatData = {
      message: data?.description ?? " ",
      parentId: parentForm?.id,
    };
    try {
      if (parentForm?.type === "CONTACT") {
        const resStatus = await UPDATEPARENTSTATUST({
          prospectId: parentForm?.id,
          type: "PROSPECT",
        });

        if (resStatus?.error) {
          return;
        }
      }
      const res = await CONTACTPARENT({ ...formatData });
      if (res?.data) {
        return navigation("success", { replace: true });
      }
      setError("root", { messauge: "une error est survenu" });
    } catch (error) {
      setError("root", { message: "une error est survenu" });
    }
  };

  return (
    <Template
      header={
        <div className="flex justify-between items-center px-1 py-4">
          <div className="flex gap-2 items-center">
            <div
              className="cursor-pointer flex items-center justify-center px-1"
              onClick={() => {
                navigation(-1);
              }}
            >
              <ArrowLeft />
            </div>
            <div className="h-8 w-8 bg-primary rounded-full flex justify-center items-center">
              <User color="#fff" size={18} />
            </div>

            <div className="text-md">
              {params?.name ? (
                <>
                  <p className="-mb-1 font-bold">
                    {/* {eleve?.firstName} {eleve?.lastName} */}
                    {studentDemo?.fullName}
                  </p>
                  <p className="text-xs tew">{studentDemo?.classe}</p>
                </>
              ) : (
                <p className="-mb-1 font-bold">{parentForm?.fullName}</p>
              )}
            </div>
          </div>
          {params?.name ? (
            <h2
              className="text-center px-3 py-1 rounded-xl text-[10px]"
              style={{ backgroundColor: "rgba(0,0,0,.1)" }}
            >
              Diagnostic {params?.name}
            </h2>
          ) : null}
        </div>
      }
      headerStyle={{ height: 60 }}
      style={{
        backgroundColor: "#fff",
        padding: 10,
      }}
      footer={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            padding: "0px 20px",
            marginTop: 5,
          }}
        >
          <Button
            style={{
              backgroundColor: "#003880",
              flex: 1,
              height: 40,
              borderRadius: 20,
            }}
            radius={"sm"}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            Envoyer
          </Button>
        </div>
      }
    >
      <RenderData
        data={{}}
        render={(item) => (
          <>
            <div className="border-t-[1px] pt-4 border-gray">
              <span className="text-md">
                Pour bénéficier de notre accompagnement, merci d’envoyer ce
                formulaire :
              </span>
            </div>
            <form
              className="flex flex-col gap-2 mt-4"
              // onSubmit={handleSubmit(onSubmit)}
            >
              {errors?.root ? errors?.root?.message : null}

              <p className="font-bold mt-4">Prenom et nom</p>
              <div className="">
                <div className="flex flex-col border-gray border-2 rounded-lg p-2">
                  <label
                    htmlFor="school"
                    className="text-sm text-[rgba(0,0,0,0.6)]"
                  >
                    Ecole
                  </label>
                  <input
                    name="fullName"
                    id="fullName"
                    placeholder="ecrire ici..."
                    className={"focus:outline-none"}
                    aria-invalid={errors?.fullName ? "true" : "false"}
                    type="text"
                    {...register("fullName", {
                      required: "Le Prenom et nom sont obligatoire",
                      disabled: true,
                    })}
                  />
                </div>
                {errors?.school ? (
                  <div className="text-danger text-xs mt-1">
                    {errors?.school?.message}
                  </div>
                ) : null}
              </div>

              <p className="font-bold mt-4">Telephone</p>
              <div className="">
                <div className="flex flex-col border-gray border-2 rounded-lg p-2">
                  <label
                    htmlFor="school"
                    className="text-sm text-[rgba(0,0,0,0.6)]"
                  >
                    Numero de telephone
                  </label>
                  <input
                    name="phone"
                    id="phone"
                    placeholder="ecrire ici..."
                    className={"focus:outline-none"}
                    aria-invalid={errors?.phone ? "true" : "false"}
                    type="text"
                    {...register("phone", {
                      required: "Le numero de telephone et obligatoire",
                      disabled: true,
                    })}
                  />
                </div>
                {errors?.school ? (
                  <div className="text-danger text-xs mt-1">
                    {errors?.school?.message}
                  </div>
                ) : null}
              </div>

              <p className="font-bold mt-4">Description</p>

              <div className="">
                <div className="flex flex-col border-gray border-2 rounded-lg p-2">
                  <label
                    htmlFor="fullNameEnfant"
                    className="text-sm text-[rgba(0,0,0,0.6)]"
                  >
                    Mettre la description ( facultatif )
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    placeholder="ecrire ici..."
                    className="focus:outline-none"
                    rows={3}
                    type="text"
                    {...register("description")}
                  />
                </div>
                {errors?.description ? (
                  <div className="text-danger text-xs mt-1">
                    {errors?.description?.message}
                  </div>
                ) : null}
              </div>
            </form>
          </>
        )}
      />
    </Template>
  );
}

export default ContactGenimiPage;
