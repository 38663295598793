import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { CameraRotate, Check, X } from "tabler-icons-react";

function CameraPage() {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [mirrored, setMirrored] = useState(false);

  const navigation = useNavigate();

  // create a capture function
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const retake = () => {
    if (!imgSrc) {
      navigation(-1);
    } else {
      setImgSrc(null);
    }
  };

  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <div className="relative">
      {imgSrc ? (
        <div className="h-[100dvh] flex flex-col items-center justify-center">
          <img src={imgSrc} alt="webcam" className="" />
        </div>
      ) : (
        <Webcam
          ref={webcamRef}
          mirrored={mirrored}
          screenshotFormat="image/jpeg"
          screenshotQuality={0.8}
          style={{ height: "100vh" }}
          allowFullScreen
          allowTransparency
          videoConstraints={{ facingMode: "environment" }}
        />
      )}

      {/* {devices.map((device, key) => (
        <div>
          <Webcam
            audio={false}
            videoConstraints={{ deviceId: device.deviceId }}
          />
          {device.label || `Device ${key + 1}`}
        </div>
      ))} */}
      <div className="absolute bottom-0 z-10  flex justify-between items-center w-full p-10">
        <div className="controls">
          <div
            className="bg-[#9993] p-2 rounded-full"
            onClick={() =>
              imgSrc
                ? navigation("/chat", { state: { imgSrc }, replace: true })
                : setMirrored(!mirrored)
            }
          >
            {/* <input
              type="checkbox"
              checked={mirrored}
              onChange={(e) => setMirrored(e.target.checked)}
            />
            <label>Mirror</label> */}
            {imgSrc ? (
              <Check size={35} color="green" />
            ) : (
              <CameraRotate size={35} color="#999" />
            )}
          </div>
        </div>
        <div>
          {imgSrc ? null : ( // <button onClick={retake}>Retake photo</button>
            //   <button onClick={capture}>Capture photo</button>
            <div
              onClick={capture}
              className="w-16 h-16 rounded-full border-4 border-[#999] bg-[#9992] hover:bg-[#9993]"
            ></div>
          )}
        </div>
        <div className="bg-[#9993] p-2 rounded-full" onClick={retake}>
          <X size={35} color={imgSrc ? "red" : "#999"} />
        </div>
      </div>
    </div>
  );
}

export default CameraPage;
