function Divider() {
  return (
    <div
      className=""
      style={{
        width: "100%",
        height: 1,
        backgroundColor: "rgba(0,0,0,0.1)",
        margin: "10px 0",
      }}
    ></div>
  );
}

export default Divider;
