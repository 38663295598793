const { createSlice } = require("@reduxjs/toolkit");

const eleveSlice = createSlice({
  name: "eleve",
  initialState: {
    eleves: [],
    currentEleve: null,
    tentatives: [],
    historiques: [],
  },
  reducers: {
    setEleves: (state, { payload }) => {
      state.eleves = [...payload];
    },

    setCurrentEleve: (state, { payload }) => {
      state.currentEleve = payload;
    },

    setTentatives: (state, { payload }) => {
      // const tentativeEleveIndex = state.tentatives.findIndex(
      //   (tentative) => tentative?.eleveId === payload.eleveId
      // );

      // if (tentativeEleveIndex !== -1) {
      //   state.tentatives[tentativeEleveIndex] = { ...payload };
      // } else {
      //   state.tentatives = [...state?.tentatives, { ...payload }];
      // }
      state.tentatives = [...state?.tentatives, { ...payload }];
    },

    setHistoriques: (state, { payload }) => {
      const historiqueTentativeIndex = state.historiques.findIndex(
        (historiqye) => historiqye?.tentativeId === payload.tentativeId
      );

      if (historiqueTentativeIndex !== -1) {
        state.historiques[historiqueTentativeIndex] = { ...payload };
      }

      state.historiques = [...state?.historiques, { ...payload }];
    },
  },
});

export const getEleves = (state) => state?.user?.eleveReducer?.eleves;
export const getCurrentEleve = (state) =>
  state?.user?.eleveReducer?.currentEleve;
export const getTentatives = (state) => state?.user?.eleveReducer?.tentatives;
export const getHistoriques = (state) => state?.user?.eleveReducer?.historiques;

export const { setEleves, setCurrentEleve, setTentatives, setHistoriques } =
  eleveSlice.actions;
export default eleveSlice.reducer;
