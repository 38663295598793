import {
  AUTH_PATH,
  NUMBER_VERIFIER_PATH,
  REGISTER_PATH,
  UPDATE_PASSWORD_PATH,
} from "../../routes/api.path";
import { ApiMananger } from "./apiMananger";

export const userApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getUserByPhone: build.query({
      query: ({ phone }) => `${NUMBER_VERIFIER_PATH}${phone}/`,
      providesTags: ["Users"],
    }),
    loginUser: build.mutation({
      query({ body, token }) {
        return {
          url: AUTH_PATH + "/login/",
          method: "POST",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    registerUser: build.mutation({
      query({ body, token }) {
        return {
          url: REGISTER_PATH,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
            // Referer: "https://genimi-admin.bakeli.tech/",
            // "Referrer-Policy": "strict-origin-when-cross-origin",
          },
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    updatePassword: build.mutation({
      query({ body, token }) {
        return {
          url: UPDATE_PASSWORD_PATH,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
            // Referer: "https://genimi-admin.bakeli.tech/",
            // "Referrer-Policy": "strict-origin-when-cross-origin",
          },
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGetUserByPhoneQuery,
  useRegisterUserMutation,
  useUpdatePasswordMutation,
} = userApi;
