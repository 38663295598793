function ListeNiveaux({ niveaux }) {
  return (
    <div className="">
      {niveaux && niveaux.length > 0 ? (
        niveaux.map((niveau, index) => (
          <h2
            key={index}
            style={{
              fontSize: ".7rem",
            }}
            className="mb-2"
          >
            {niveau?.name}
          </h2>
        ))
      ) : (
        <h2
          style={{
            fontSize: ".7rem",
          }}
          className="mb-2"
        >
          pas de niveaux
        </h2>
      )}
    </div>
  );
}

export default ListeNiveaux;
