import Timeline from "components/Timeline";
import React from "react";
import styled from "styled-components";
import { ArrowLeft, User, X } from "tabler-icons-react";
import TextToSpeech from "./TextSpeach";
import Credit from "components/Credit";
import Figure from "./Figure";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 5px;
//   // height: 60px;
//   background-color: blue;
// `;

const ContentWrapper = styled.div`
  /* Ajoutez des styles pour la disposition des éléments à l'intérieur */
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  min-width: 40px;
  padding: 0 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const AnswerArea = styled.div`
  border: 2px dashed #ccc;
  ${(props) => (props.correct === "false" ? "background-color: red;" : null)}
  border-radius: 8px;
  min-width: 50px;
  padding: 0 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MathGame = ({
  data,
  currentLevel,
  current,
  selected,
  handleAddWord,
  handleRemoveWord,
  userAnswer,
  handleCheck,
  eleve,
  studentDemo,
  randomeImage,
  handleGoBack,
  onTextRecorded,
  textRecorded,
  onSelecteFigure,
}) => {
  const handleDrop = (e, cardValue) => {
    e.preventDefault();
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const handleDrag = (e, cardValue) => {
    e.dataTransfer.setData("cardValue", cardValue);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <GameHeader
          handleGoBack={handleGoBack}
          eleve={eleve}
          current={current}
          data={data[currentLevel]}
          randomeImage={randomeImage}
          studentDemo={studentDemo}
        />
        <MathCardContainer
          data={data[currentLevel]?.data}
          item={data[currentLevel]?.data[current]}
          current={current}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleRemoveWord={handleRemoveWord}
          userAnswer={userAnswer}
          selected={selected}
        />
      </div>
      {data && (
        <MathGameControls
          choices={data[currentLevel]?.data[current]?.choices}
          type={data[currentLevel]?.data[current]?.type}
          consigne={data[currentLevel]?.data[current]?.consigne}
          handleAddWord={handleAddWord}
          handleCheck={handleCheck}
          handleDrag={handleDrag}
          onTextRecorded={onTextRecorded}
          selected={selected}
          textRecorded={textRecorded}
          userAnswer={userAnswer}
          current={current}
          currentLevel={currentLevel}
          data={data}
          onSelecteFigure={onSelecteFigure}
        />
      )}
    </div>
  );
};

// Le reste du code reste le même

export default MathGame;

export const GameHeader = ({
  handleGoBack,
  eleve,
  current,
  data,
  randomeImage,
  studentDemo,
}) => {
  return (
    <ContentWrapper className="">
      <Header
        handleGoBack={handleGoBack}
        eleve={eleve}
        studentDemo={studentDemo}
      />
      <Timeline data={data} />
      <ProgressIndicator
        current={current}
        data={data?.data}
        randomeImage={randomeImage}
      />
    </ContentWrapper>
  );
};

export const Header = ({ handleGoBack, eleve, type, studentDemo }) => {
  return (
    <div className="flex justify-between items-center  py-2 h-[80px] sticky top-0 bg-white z-30">
      <div className="flex gap-2 mt-[-10px]">
        <div
          className="cursor-pointer flex items-center justify-center px-1"
          onClick={handleGoBack}
        >
          <ArrowLeft />
        </div>
        <div className="h-8 w-8 bg-primary rounded-full flex justify-center items-center">
          <User color="#fff" size={18} />
        </div>
        <div className="text-sm">
          <p className="-mb-1 font-bold">
            {/* {eleve?.firstName}
              {eleve?.lastName} */}
            {studentDemo?.fullName}
          </p>
          <p className="text-xs">{studentDemo?.classe}</p>
        </div>
      </div>
      <div className="flex flex-col items-end gap-2 justify-center scale-90">
        <Credit />
        <h2
          className="text-center px-3 py-1 rounded-2xl text-xs"
          style={{ backgroundColor: "rgba(0,0,0,.1)" }}
        >
          <p className="scale-90">Diagnostic {type ?? "Mathematique"}</p>
        </h2>
      </div>
    </div>
  );
};

export const ProgressIndicator = ({ current, data, randomeImage }) => {
  return (
    <div className="flex gap-2" style={{ marginTop: 0 }}>
      <div className="w-full">
        <div
          className=""
          style={{
            borderRadius: 10,
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            className="relativ flex justify-end"
            style={{
              width: `${
                (current * 100) / data.length > 0
                  ? (current * 100) / data.length
                  : 5
              }%`,
              transition: "width 1s ease-in-out",
            }}
          >
            <img
              src={`../../../../assets/kids/${randomeImage}`}
              alt="progress"
              className="w-6"
            />
          </div>
        </div>
        <div
          className=""
          style={{
            borderRadius: 10,
            height: 8,
            width: "100%",
            marginBottom: 20,
            backgroundColor: "rgba(0,0,0,0.1)",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            className=""
            style={{
              width: `${(current * 100) / data.length}%`,
              height: "100%",
              backgroundColor: "rgb(100,200,50)",
              transition: "width 1s ease-in-out",
            }}
          ></div>
        </div>
      </div>
      <div className="flex items-center font-bold text-xs">
        {current + 1}/{data.length}
      </div>
    </div>
  );
};

const MathCardContainer = ({
  data,
  current,
  handleDrop,
  handleDragOver,
  handleRemoveWord,
  userAnswer,
  selected,
  item,
}) => {
  return (
    <div className="">
      {/* Affichez la question et les choix */}
      {data.length > 0 && (
        <div>
          <div className="flex gap-2">
            <img
              src={`../../../../assets/kids/${
                item?.type === "transcription"
                  ? "imp2"
                  : item?.type === "alphabet"
                  ? "alphabet"
                  : "fille2"
              }.png`}
              alt="progress"
              className="w-20"
            />
            <div className="flex items-end">
              <h4 className="text-sm">
                {item?.titre}
                {item?.type === "win" || item?.type === "wil"
                  ? ", puis séparez-les par des virgules."
                  : null}
              </h4>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 15,
              marginTop: 10,
            }}
          >
            {item?.type === "operation" ? (
              item?.consigne
                .map((item) => JSON.parse(item))
                .map((item, index) => {
                  return (
                    <div className="" key={index}>
                      {data[current]?.correction_dp.includes(item?.value) ? (
                        <AnswerArea
                          onDrop={(e) =>
                            handleDrop(e, e.dataTransfer.getData("cardValue"))
                          }
                          onDragOver={handleDragOver}
                        >
                          <b style={{ fontSize: 23 }}>{userAnswer ?? "?"}</b>
                        </AnswerArea>
                      ) : (
                        <Card>{item?.value.toString()}</Card>
                      )}
                    </div>
                  );
                })
            ) : selected.length === 0 ? (
              <span style={{ color: "gray" }}>Cliquer sur un element ...</span>
            ) : (
              <div
                className="border-b-2 w-full pb-2 flex gap-2"
                style={{ borderColor: "rgba(0,0,0,0.1)" }}
              >
                {selected.map((item, index) => (
                  <Card
                    className=""
                    key={index}
                    onClick={() => handleRemoveWord(item)}
                  >
                    {item}
                    <div
                      className=""
                      style={{
                        position: "absolute",
                        width: 10,
                        height: 10,
                        // backgroundColor: "red",
                        top: -5,
                        right: -5,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <X size={10} color="red" />
                    </div>
                  </Card>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// win: Write In Number
// wil: Write In Letter

const MathGameControls = ({
  type,
  choices,
  handleDrag,
  handleCheck,
  userAnswer,
  handleAddWord,
  selected,
  onTextRecorded,
  textRecorded,
  consigne,
  data,
  onSelecteFigure,
  currentLevel,
  current,
}) => {
  return (
    <div
      style={
        {
          // flexDirection: "row",
          // display: "flex",
          // gap: 10,
          // justifyContent: "center",
          // padding: 10,
        }
      }
      // className="bg-primary"
    >
      {type === "operation" ? (
        <div className="flex gap-2 justify-center flex-wrap">
          {choices.map((cardValue, index) => (
            <Card
              key={index}
              draggable
              onDragStart={(e) => handleDrag(e, cardValue)}
              onClick={() => handleCheck(cardValue)}
              style={{ borderColor: userAnswer === cardValue && "green" }}
            >
              {cardValue}
            </Card>
          ))}
        </div>
      ) : type === "win" || type === "wil" ? (
        <div className="w-full flex flex-col gap-10">
          <TextToSpeech
            textRecorded={textRecorded}
            onChange={onTextRecorded}
            text={consigne?.join("; ")}
          />
        </div>
      ) : type === "figure" ? (
        <Figure
          data={data[currentLevel]?.data[current] ?? {}}
          onSelecte={onSelecteFigure}
        />
      ) : (
        <div className="flex gap-2 justify-center flex-wrap">
          {consigne.map((item, index) => (
            <Card
              className=""
              key={index}
              onClick={() => handleAddWord(item)}
              style={{
                opacity: selected.includes(item) ? 0.3 : 1,
              }}
            >
              {item}
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};
