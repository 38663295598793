import React from "react";
import { createPortal } from "react-dom";
import { bgColorPrimary } from "constants";
import { Download, X } from "tabler-icons-react";

function ModalInstall({ opened, onClosed, onInstall }) {
  if (!opened) {
    return;
  }

  return createPortal(
    <div
      style={{
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translate(-50%)",
        // maxWidth: 600,
        width: "100%",
        height: "100dvh",
        backgroundColor: "rgba(0,0,0,0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        // zIndex: 199,
      }}
    >
      <div
        className=""
        style={{
          margin: ".6rem",
          backgroundColor: "#fff",
          border: "1px solid rgba(0,0,0,0.1)",
          width: "500px",
          borderRadius: 10,
          padding: "1rem",
          position: "relative",
          transition: "all .3s",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            // backgroundColor: "red",
            padding: 4,
          }}
          onClick={onClosed}
        >
          <X size={20} />
        </div>
        <p className="text-center mb-2">Installation de l'application</p>

        <div
          style={{
            display: "grid",
            placeItems: "center",
            width: "100%",
          }}
        >
          <button
            style={{
              width: "100%",
              height: 35,
              borderRadius: 15,
              outline: "none",
              backgroundColor: bgColorPrimary,
              color: "#fff",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={onInstall}
          >
            <div className="flex gap-5">
              <Download size={20} /> <span weight={600}>Installer</span>
            </div>
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
}

export default ModalInstall;
