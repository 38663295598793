import { ApiMananger } from "./apiMananger";

const matiereApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getMatieres: build.query({
      query: () => `/matieres/`,
      providesTags: ["Matieres"],
    }),
    getMatieresWithDiagnostic: build.query({
      query: ({ classeId }) => `/m1/diagnositcs/classe/${classeId}`,
      providesTags: ["Matieres"],
    }),
  }),
});

export const { useGetMatieresQuery, useGetMatieresWithDiagnosticQuery } =
  matiereApi;
