import { ListIcons } from "assets/ListIcons";
import React, { useState } from "react";

function getIcon(data) {
  return ListIcons.filter((item) => {
    const _item = data.find((_item) => `${item?.name}-${item?.id}` === _item);
    if (_item) {
      return true;
    }
    return false;
  }).map((item) => {
    const _item = data.find((_item) => `${item?.name}-${item?.id}` === _item);
    if (_item) {
      return { ...item, key: _item };
    }
  });
}

const Figure = ({ data, onSelecte }) => {
  // console.log({ data });

  const [selected, setSelected] = useState([]);

  const newData = getIcon(data?.consigne);

  // console.log({ newData, selected });

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-4">
        {newData?.map((item, index) => (
          <div
            onClick={() => {
              setSelected([item?.key]);
              onSelecte([item?.key]);
            }}
            key={index}
            className={`${
              selected?.includes(item?.key) ? "bg-[#0b460be7]" : "bg-[#9995]"
            } flex justify-center h-[120px] rounded-lg items-center active:scale-95 transition-all cursor-pointer`}
          >
            {item?.icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Figure;
