import CallToactionBtn from "components/CallToactionBtn";
import PageHeader from "components/PageHeader";
import useGetStudent from "hooks/useGetStudent";
import { Template } from "layouts/Template";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetElevesByParentQuery } from "redux/features/eleveApi";
import {
  // getCurrentEleve,
  // getEleves,
  // getTentatives,
  setCurrentEleve,
} from "redux/features/eleveSlice";
import { getParentForm } from "redux/features/parentSlice";
import { Check, Loader } from "tabler-icons-react";

function SelectEnfantPage() {
  const navigation = useNavigate();
  const student = useGetStudent();
  const dispatch = useDispatch();

  // const students = useSelector(getEleves);
  // const studentDemo = useSelector(getCurrentEleve);

  const parent = useSelector(getParentForm);

  // const tentativeDemos = useSelector(getTentatives);

  const [selectStudent, setSelectStudent] = useState(null);

  const { data, isLoading, isError } = useGetElevesByParentQuery(
    { parentId: parent?.id },
    {
      skip: !parent?.id,
    }
  );

  const handleStart = () => {
    navigation("/" + selectStudent + "/matiere", {
      state: {
        username: `${student?.firstName} ${student?.lastName}`,
        id: student?.id,
        classe: {
          id: student?.classe?.id,
          name: student?.classe?.name,
        },
        school: student?.school?.name,
      },
      // replace: true,
    });
  };

  return (
    <Template
      header={<PageHeader title={"Liste de mes enfants"} />}
      style={{
        backgroundColor: "#fff",
        padding: 10,
      }}
      footerStyle={{ height: 100, padding: "0 10px" }}
      footer={
        <div className="">
          <div
            className="bg-white shadow-lg text-center border-2 rounded-full p-2 mx-6 cursor-pointer hover:bg-gray mt-1"
            onClick={() =>
              navigation("../form", { state: { nombre: "n", exist: true } })
            }
          >
            ajouter un enfant
          </div>

          <div className="px-0 my-1">
            <button
              className="bg-primary text-white disabled:opacity-40"
              style={{
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                gap: 10,
                padding: "10px",
                // margin: "20px 10px",
                width: "100%",
              }}
              disabled={!selectStudent}
              onClick={handleStart}
            >
              Continuer
            </button>
          </div>
        </div>
      }
    >
      <div className="flex-1 overflow-auto relative flex-col">
        <div className="">
          <p className="text-primary font-bold text-lg">
            Selectionner un enfant pour débuter le diagnostic :
          </p>

          <div className="flex flex-col gap-4 mt-10">
            {isLoading ? (
              <Loader size={30} className="animate-spin self-center my-5" />
            ) : isError ? (
              "Une erreur est survenu"
            ) : data?.data.length > 0 ? (
              data?.data?.map((eleve, index) => (
                <div
                  key={eleve?.id + " " + index}
                  onClick={() => {
                    dispatch(
                      setCurrentEleve({
                        id: eleve?.id,
                        classe: eleve?.classe?.name,
                        classeId: eleve?.classeId,
                        fullName: eleve?.firstName + " " + eleve?.lastName,
                      })
                    );
                    setSelectStudent(eleve?.id);
                  }}
                  className="flex items-center justify-between hover:bg-[rgba(0,0,0,0.1)] bg-[rgba(0,0,0,0.05)] cursor-pointer rounded-lg px-3 gap-4 py-3"
                >
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-gray rounded-full p-3">
                      <img
                        src="/icons/faceBlack.png"
                        alt=""
                        className="w-full h-full"
                      />
                    </div>
                    <div className="">
                      <p>
                        {eleve?.firstName} {eleve?.lastName}
                      </p>
                      <p>{eleve?.classe?.name}</p>
                    </div>
                  </div>
                  {/* {tentativeDemos?.filter(
                    (tentative) => tentative?.eleveId === eleve?.id
                  )?.length > 0 ? (
                    <p className="font-bold">
                      {
                        tentativeDemos?.filter(
                          (tentative) => tentative?.eleveId === eleve?.id
                        )?.length
                      }{" "}
                      {`tentative${
                        tentativeDemos?.filter(
                          (tentative) => tentative?.eleveId === eleve?.id
                        )?.length > 1
                          ? "s"
                          : ""
                      }
                  `}
                    </p>
                  ) : null} */}

                  <div className="flex items-center gap-5">
                    <p className="font-bold">
                      {eleve?.tentatives}{" "}
                      {`tentative${eleve?.tentatives > 1 ? "s" : ""}
                  `}
                    </p>

                    {selectStudent && selectStudent === eleve?.id ? (
                      <div className="bg-[green] rounded-full p-1">
                        <Check color="#fff" size={16} />
                      </div>
                    ) : null}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-lg text-center">Pas d'enfants</div>
            )}
          </div>
        </div>
      </div>

      <CallToactionBtn />
    </Template>
  );
}

export default SelectEnfantPage;
