import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "routes/api";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  refetchOnReconnect: true,
  keepUnusedDataFor: 604800,
  reducerPath: "genimiApi",
  endpoints: () => ({}),
});

export const ApiMananger = baseApi.enhanceEndpoints({
  addTagTypes: [
    "Coachs",
    "Parents",
    "Fiches",
    "Users",
    "Eleves",
    "Appels",
    "comprehensions",
    "Paiements",
    "Evaluations",
    "Niveaux",
    "Classes",
    "Diagnostics",
  ],
});
