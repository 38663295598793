import React, { useMemo, useState } from "react";
import Button from "components/Button";
import { Template } from "layouts/Template";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MathGame from "pages/home/components/MathPage";
import Finish from "pages/home/components/Finish";
import {
  useAddDiagnostiqueEleveTentativeMutation,
  useGetEpreuvexByDiagnostiqueQuery,
} from "redux/features/diagnostiqueApi";
import useGetUserInfo from "hooks/useGetUserInfo";
import { useGetDiagnostiqueWithNiveauxQuery } from "redux/features/parentApi";
import { useDispatch } from "react-redux";
import {
  setEpreuveNote,
  setEscoreNiveau,
} from "redux/features/diagnostiqueSlice";
import useQuery from "components/useQuery";
import { useSelector } from "react-redux";
import { useGetEleveQuery } from "redux/features/eleveApi";
import { progressImage } from "utils/images";
import Loader from "components/Loader";
import { getNumber } from "utils/soted";
import { compareText, correctFigure } from "utils/comparentText";
import { getCurrentEleve, setTentatives } from "redux/features/eleveSlice";
import { toast } from "react-toastify";

function ExerciceMathPage() {
  const params = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  const searchParams = useQuery();

  const diagnostiqueStore = useSelector(
    (state) => state?.user?.diagnostiqueReducer
  );

  const studentDemo = useSelector(getCurrentEleve);

  // useEffect(() => {
  //   console.log({ diagnostiqueStore });
  // }, []);

  const dispatch = useDispatch();

  const { csrfToken } = useGetUserInfo();

  const { data: datas } = useGetEpreuvexByDiagnostiqueQuery(
    parseInt(params.id)
  );

  const { data: eleve } = useGetEleveQuery(params?.eleveId);

  const { data: diagnostiqueNiveauxData, isLoading } =
    useGetDiagnostiqueWithNiveauxQuery(parseInt(params.id));

  const [diagnostiqueNiveaux, setDiagnostiqueNiveaux] = useState({});

  const [addDiagnostiqueEleveTentative, { isLoading: addLoading }] =
    useAddDiagnostiqueEleveTentativeMutation();

  const [currentLevel, setCurrentLevel] = useState(
    parseInt(searchParams.get("niveau")) ?? 0
  );

  const [current, setCurrent] = useState(
    parseInt(searchParams.get("epreuve")) ?? 0
  );

  const [selected, setSelected] = useState([]);
  const [end, setEnd] = useState(false);
  const [userAnswer, setUserAnswer] = useState(null);
  const [result] = useState(false);
  const [textRecorded, setTextRecorded] = useState("");

  const [selecteFigure, onSelecteFigure] = useState("");

  /**
   * Ajout de l'element cliquer dans le tableau
   * @param {*} value
   */
  const handleAddWord = (value) => {
    !selected.includes(value)
      ? setSelected([...selected, value])
      : setSelected(selected.filter((item) => item !== value));
  };

  /**
   *
   * @param {string} value
   */
  const handleRemoveWord = (value) => {
    selected.includes(value) &&
      setSelected(selected.filter((item) => item !== value));
  };

  /**
   * Comparaison de deux tableaux
   * @param {*} tableau1
   * @param {*} tableau2
   * @returns
   */
  function isEqual(tableau1, tableau2) {
    if (tableau1?.length !== tableau2?.length) return false;

    return tableau1.every((value, index) => value === tableau2[index]);
  }

  /**
   * Go next exercice
   * @returns
   */
  const handleNext = async () => {
    const currentExercise =
      diagnostiqueNiveaux?.data[currentLevel]?.data[current];
    const correctAnswer = currentExercise?.correction_dp;

    let note = 0;

    // WIN :
    // WIL :
    if (currentExercise?.type === "win" || currentExercise?.type === "wil") {
      note = compareText(
        textRecorded.split(",").join(" ").toLowerCase(),
        currentExercise.correction_dp.join(" ").toLowerCase(),
        currentExercise.bareme
      )?.score;
    } else if (currentExercise?.type === "figure") {
      // return console.log({selecteFigure});

      note = correctFigure(
        selecteFigure.join(" "),
        currentExercise?.correction_dp.join(" "),
        currentExercise?.bareme
      );
    } else {
      if (selected?.length > 0 || textRecorded || userAnswer) {
        if (isEqual(correctAnswer, !userAnswer ? selected : [userAnswer])) {
          note = currentExercise?.bareme;
        }
      }
    }

    try {
      dispatch(setEscoreNiveau(note));

      dispatch(
        setEpreuveNote({
          epreuveId: currentExercise?.id,
          note: Math.round(note),
          niveau: diagnostiqueNiveaux?.data[currentLevel]?.niveau,
        })
      );

      setUserAnswer(null);
      setSelected([]);
      onSelecteFigure([]);
      setTextRecorded("");

      const nextEpreuveIndex =
        current + 1 === diagnostiqueNiveaux?.data[currentLevel]?.data?.length
          ? current
          : current + 1;

      navigation(
        `?niveau=${currentLevel}&epreuve=${nextEpreuveIndex}`,
        current + 1 === diagnostiqueNiveaux?.data[currentLevel]?.data?.length
          ? { state: { end: true }, replace: true }
          : { replace: true }
      );

      if (
        current + 1 ===
        diagnostiqueNiveaux?.data[currentLevel]?.data?.length
      ) {
        setEnd(true);
      }
    } catch (error) {
      console.error("Erreur lors du passage à l'exercice suivant :", error);
    }
  };

  const checkAnswer = (value) => {};

  const handleCheck = (value) => {
    setUserAnswer(value);
  };

  /**
   * Filtrer les diagnostics par niveaux
   */
  useMemo(() => {
    if (diagnostiqueNiveauxData) {
      setDiagnostiqueNiveaux({
        ...diagnostiqueNiveaux,
        count: diagnostiqueNiveauxData?.count,
        data: [...diagnostiqueNiveauxData?.data].sort(
          (a, b) => getNumber(a.niveau) - getNumber(b.niveau)
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnostiqueNiveauxData]);

  /**
   * Mettre a jour le niveau et l'exercice courrant
   */
  useMemo(() => {
    setCurrentLevel(parseInt(searchParams.get("niveau")));
    setCurrent(parseInt(searchParams.get("epreuve")));
  }, [searchParams]);

  /**
   * Passage de niveau
   * @returns
   */
  const handleContinueNextLevel = async () => {
    const hasNextLevel = diagnostiqueNiveaux?.data?.length === currentLevel + 1;

    //
    const isBelowSeuil =
      diagnostiqueStore?.notes
        .filter(
          (note) =>
            note?.niveau === diagnostiqueNiveaux?.data[currentLevel]?.niveau
        )
        .reduce((acc, curr) => acc + curr?.note, 0) <
      diagnostiqueNiveaux?.data[currentLevel]?.seuil;

    if (hasNextLevel || isBelowSeuil) {
      try {
        const { score: scoreNiveau, ...res } = diagnostiqueStore;

        const response = await addDiagnostiqueEleveTentative({
          body: { ...res, status: "VALIDE", diagnosticId: parseInt(params.id) },
          token: csrfToken,
        });

        if (!response?.error) {
          setEnd(false);

          dispatch(
            setTentatives({
              diagnostique: diagnostiqueStore,
              eleveId: studentDemo?.id,
              diagnosticId: response?.data?.id,
              type: "MATHEMATIQUE",
              createdAt: new Date(Date.now()),
            })
          );

          navigation(`../analyse/${response?.data?.id}`, {
            replace: true,
          });
          return;
        }
        toast.error("Erreur l'or de l'envoi");
      } catch (error) {
        console.error(
          "Erreur lors de la continuation vers le niveau suivant :",
          error
        );
      }
    } else {
      setEnd(false);
      navigation(`?niveau=${currentLevel + 1}&epreuve=0`, {
        replace: true,
        state: {},
      });
    }
  };

  return isLoading ? (
    <div className="h-[100dvh] w-full flex flex-col justify-center items-center">
      <Loader />
      <p>Chargement des exercices...</p>
    </div>
  ) : diagnostiqueNiveauxData?.count === 0 ? (
    <div className="h-[100dvh] w-full flex justify-center items-center flex-col gap-4">
      <p>Aucune question pour le moment</p>
      <Button radius={"md"} onClick={() => navigation(-1)}>
        retour
      </Button>
    </div>
  ) : (
    <Template
      footer={
        end || location.state?.end ? null : (
          <div
            style={{
              display: "flex",
              gap: 10,
              padding: "0 10px",
              marginTop: 5,
            }}
          >
            {diagnostiqueNiveaux?.count === 0 ? (
              <Button
                onClick={() => navigation(-1, { replace: true })}
                style={{ width: "100%" }}
                radius={"md"}
              >
                Retour
              </Button>
            ) : (
              !end && (
                <Button
                  onClick={handleNext}
                  style={{ width: "100%" }}
                  radius={"md"}
                  disabled={
                    userAnswer !== null ||
                    selected?.length > 0 ||
                    textRecorded ||
                    selecteFigure?.length > 0
                      ? false
                      : true
                  }
                >
                  Valider
                </Button>
              )
            )}
          </div>
        )
      }
    >
      {/* Word quizz */}
      {datas?.count === 0 ? (
        "Pas d'exercice"
      ) : end || location.state?.end ? (
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {isLoading || !diagnostiqueNiveaux?.data ? (
            <Loader />
          ) : (
            <Finish
              data={diagnostiqueNiveaux?.data[currentLevel]}
              globalNote={diagnostiqueNiveaux?.data[currentLevel]?.data?.reduce(
                (acc, cur) => acc + cur?.bareme,
                0
              )}
              score={diagnostiqueStore?.notes
                .filter(
                  (note) =>
                    note?.niveau ===
                    diagnostiqueNiveaux?.data[currentLevel]?.niveau
                )
                .reduce((acc, curr) => acc + curr?.note, 0)}
              note={diagnostiqueStore?.score}
              onRestart={() => window.location.reload()}
              seuil={diagnostiqueNiveaux?.data[currentLevel]?.seuil}
              onQuit={async () => {
                // return console.log({diagnostiqueStore});
                try {
                  await addDiagnostiqueEleveTentative({
                    body: { ...diagnostiqueStore },
                    token: csrfToken,
                  });
                  // console.log({ res });
                  navigation("/" + params?.eleveId + "/matiere", {
                    replace: true,
                  });
                } catch (error) {
                  console.log({ error });
                }
              }}
              onCountinue={handleContinueNextLevel}
              loading={addLoading}
              finish={
                diagnostiqueNiveaux?.data?.length === currentLevel + 1 ||
                diagnostiqueStore?.score <
                  diagnostiqueNiveaux?.data[currentLevel]?.seuil
              }
            />
          )}
        </div>
      ) : (
        <MathGame
          data={ diagnostiqueNiveaux && diagnostiqueNiveaux?.data ? diagnostiqueNiveaux?.data : []}
          eleve={eleve}
          studentDemo={studentDemo}
          userAnswer={userAnswer}
          currentLevel={currentLevel}
          current={current}
          selected={selected}
          result={result}
          handleCheck={handleCheck}
          checkAnswer={checkAnswer}
          handleAddWord={handleAddWord}
          handleRemoveWord={handleRemoveWord}
          randomeImage={progressImage[currentLevel]}
          handleGoBack={() => navigation(-1)}
          textRecorded={textRecorded}
          onTextRecorded={setTextRecorded}
          // Figure
          onSelecteFigure={onSelecteFigure}
        />
      )}
    </Template>
  );
}

export default ExerciceMathPage;
