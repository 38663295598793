import React, { useState } from "react";
import AudioRecording from "./AudioRecording";
import Timeline from "components/Timeline";
import TextToSpeech from "./TextSpeach";
// import { Header } from "./MathPage";
import KeyBoard from "components/KeyBoard";
import ProgressIndicator from "./ProgressIndicator";
import Figure from "./Figure";
import TSQ from "./TSQ";

function getExerciceType(data = [], currentLevel, current) {
  return data[currentLevel]?.data[current]?.type;
}

function WordPage({
  data,
  currentLevel,
  current,
  selected,
  onTranscription,
  handleAddWord,
  handleRemoveWord,
  resetTranscription,
  onTextChange,
  textRecorded,
  randomeImage,
  // eleve,
  // studentDemo,
  // handleGoBack,
  handleAddTextSpeachWord,
  selectedTextSpeach,
  onGetWordSelected,
  suggestionManquante,
  suggestionInputActive,
  onPressKebeard,
  onCheckInput,
  onSelecteFigure,
  getTimer,
  onSelectChoice,
}) {
  const setInputValue = useState("")[1];

  const exerciceType = getExerciceType(data, currentLevel, current);
  console.log("***********");
  console.log({
    currentData: data[currentLevel]?.data,
    currentLevel,
    current,
    data,
  });

  return (
    <div className="scale-x-95 h-[100dvh] flex flex-col justify-between">
      <div className="">
        {/* <Header
          handleGoBack={handleGoBack}
          studentDemo={studentDemo}
          eleve={eleve}
          type={"Lecture"}
        /> */}
        <div className="px-1">
          <Timeline data={data[currentLevel]} />
          <ProgressIndicator
            current={current}
            data={data[currentLevel]?.data}
            randomeImage={randomeImage}
            selected={selected}
            handleRemoveWord={handleRemoveWord}
            selectedTextSpeach={selectedTextSpeach}
            suggestionManquante={suggestionManquante}
            onCheckInput={onCheckInput}
            suggestionInputActive={suggestionInputActive}
          />
        </div>
      </div>

      <div
        className={`px-1 ${
          exerciceType === "transcription" ||
          exerciceType === "dicteeChronometree" ||
          exerciceType === "tsq"
            ? "flex-1"
            : null
        }`}
      >
        {exerciceType === "organisation" ? (
          <div
            style={{
              display: "flex",
              gap: 10,
              flexWrap: "wrap",
              marginBottom: 10,
            }}
          >
            {data[currentLevel]?.data[current]?.choices.map((item, index) => (
              <div
                className="bg-[#9993] px-3 py-1 rounded-lg cursor-pointer text-sm"
                key={index}
                onClick={() => handleAddWord(item + "-" + index)}
                style={{
                  opacity: selected.includes(item + "-" + index) ? 0.3 : 1,
                }}
              >
                {item}
              </div>
            ))}
          </div>
        ) : exerciceType === "lettreManquante" ? (
          suggestionInputActive !== null ? (
            <div className="mt-4">
              <KeyBoard onPress={onPressKebeard} canClear />
            </div>
          ) : null
        ) : exerciceType === "alphabet" ? (
          <>
            <div className="mb-2">
              <KeyBoard
                onPress={(value) => {
                  setInputValue(value);
                  handleAddWord(value.toUpperCase());
                }}
              />
            </div>
            <div></div>
          </>
        ) : exerciceType === "transcription" ? (
          <>
            <AudioRecording
              data={data[currentLevel]?.data[current]}
              current={current}
              onSelect={(value) => onTranscription(value)}
              resetTranscription={resetTranscription}
              onGetWordSelected={onGetWordSelected}
            />
          </>
        ) : exerciceType === "dictee" ? (
          <TextToSpeech
            text={data[currentLevel]?.data[current]?.correction_dp.join(" ")}
            onChange={onTextChange}
            handleValidate={handleAddTextSpeachWord}
            textRecorded={textRecorded}
            selectedTextSpeach={selectedTextSpeach}
            current={current}
          />
        ) : exerciceType === "dicteeChronometree" ? (
          <>
            <AudioRecording
              data={data[currentLevel]?.data[current]}
              current={current}
              onSelect={(value) => onTranscription(value)}
              resetTranscription={resetTranscription}
              onGetWordSelected={onGetWordSelected}
              getTimer={getTimer}
            />
          </>
        ) : exerciceType === "figure" ? (
          <Figure
            data={data[currentLevel]?.data[current] ?? {}}
            onSelecte={onSelecteFigure}
          />
        ) : exerciceType === "tsq" ? (
          <TSQ
            data={data[currentLevel]?.data[current] ?? {}}
            onSelectChoice={onSelectChoice}
          />
        ) : null}
      </div>
    </div>
  );
}

export default WordPage;
