const { createSlice } = require("@reduxjs/toolkit");

const parentSlice = createSlice({
  name: "parent",
  initialState: { form: null, credit: [], reset: false },
  reducers: {
    setParentForm(state, { payload }) {
      state.form = payload;
    },

    setIncreaseCredit(state) {
      const findIndex = state.credit.findIndex(
        (item) => item?.phone === state?.form?.phone
      );

      if (findIndex !== -1) {
        if (state.credit[findIndex].points > 0) {
          state.credit[findIndex].points -= 25;
        }
      }
    },
    setNewParentCredit(state, { payload }) {
      const findIndex = state.credit.findIndex(
        (item) => item?.phone === payload?.phone
      );

      if (findIndex !== -1) {
        state.credit[findIndex].points = 100;
      } else {
        state.credit = [...state.credit, { ...payload, points: 100 }];
      }
    },
    clearParentForm(state) {
      state.form = null;
    },
    clearParentCredit(state) {
      state.credit = [];
    },
    setResetParent(state) {
      state.reset = false;
    },
  },
});

export const getParentForm = (state) => state?.user?.parentReducer?.form;
export const getParentCredit = (state) => state?.user?.parentReducer?.credit;
export const getParentResetStatus = (state) =>
  state?.user?.parentReducer?.reset;

export const {
  setParentForm,
  setIncreaseCredit,
  setNewParentCredit,
  clearParentForm,
  setResetParent,
  clearParentCredit,
} = parentSlice.actions;
export default parentSlice.reducer;
