import PageHeader from "components/PageHeader";
import { Template } from "layouts/Template";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetClassesAllQuery } from "redux/features/classeApi";
import { useAddElevesMutation } from "redux/features/eleveApi";
// import { getEleves } from "redux/features/eleveSlice";
import { getParentForm } from "redux/features/parentSlice";
import { Loader, X } from "tabler-icons-react";
import { v4 as uuidv4 } from "uuid";

function organiserClasses(classes) {
  const ordreClasses = ["CI", "CP", "CE1", "CE2", "CM1", "CM2"];

  // Trie les classes selon l'ordre spécifié
  classes.sort((a, b) => {
    return ordreClasses.indexOf(a.name) - ordreClasses.indexOf(b.name);
  });

  return classes;
}

const formatFullName = (name) => {
  const nameStringToArray = name.trim().split(" ");

  const lastName = nameStringToArray.pop();
  const firstName = nameStringToArray.join(" ");
  return { firstName, lastName };
};

function FormEnfant() {
  const navigation = useNavigate();
  const location = useLocation();

  const { data: classes, isLoading } = useGetClassesAllQuery({});

  // const dispatch = useDispatch();

  const [ADDELEVE, { isLoading: isAddLoading }] = useAddElevesMutation();

  // const students = useSelector(getEleves);

  const parent = useSelector(getParentForm);

  // console.log({ parent });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    control,
  } = useForm({
    defaultValues: {
      enfants: [{ id: uuidv4(), fullName: "", classeId: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "enfants", // unique name for your Field Array
  });
  // console.log({ classes });
  let error = false;

  const onSubmit = async (data) => {
    data.enfants?.forEach((enfant, index) => {
      if (enfant?.fullName.split(" ").length > 1) {
        return;
      } else {
        error = true;
      }
    });

    if (error) {
      return toast.error("Veuillez specifier le prenom et nom");
    }

    const dataEleves = [
      ...data.enfants?.map((enfant) => ({
        ...enfant,

        classe: classes?.data?.find(
          (classe) => classe?.id?.toString() === enfant?.classeId
        )?.name,
      })),
    ];

    // return console.log({
    //   parentId: parent?.id,
    //   data: dataEleves.map((item) => ({
    //     parentId: parent?.id,
    //     classeId: parseInt(item?.classeId),
    //     ...formatFullName(item?.fullName),
    //   })),
    // });

    // console.log({ dataEleves });

    // dispatch(
    //   setEleves(
    //     location?.state?.exist
    //       ? [
    //           ...students.map((student) => ({
    //             ...student,
    //           })),
    //           ...data.enfants?.map((enfant) => ({
    //             ...enfant,
    //             classe: classes?.find(
    //               (classe) => classe?.id?.toString() === enfant?.classeId
    //             )?.name,
    //           })),
    //         ]
    //       : [
    //           ...data.enfants?.map((enfant) => ({
    //             ...enfant,
    //             classe: classes?.find(
    //               (classe) => classe?.id?.toString() === enfant?.classeId
    //             )?.name,
    //           })),
    //         ]
    //   )
    // );
    // if (location?.state?.exist) {
    //   navigation(-1);
    // } else {
    //   navigation("../info", { replace: true });
    // }

    const formatdataEleve = dataEleves.map((item) => {
      if (
        !formatFullName(item?.fullName).firstName &&
        !formatFullName(item?.fullName).lastName
      ) {
        return setError("root", {
          message: "Il nous le prenom et nom de l'enfant",
        });
      }
      return {
        parrentId: parseInt(parent?.id),
        classeId: parseInt(item?.classeId),
        ...formatFullName(item?.fullName),
        type: "TESTEUR",
      };
    });

    try {
      let promises = [];
      for (let i = 0; i < formatdataEleve.length; i++) {
        promises.push(ADDELEVE({ body: { ...formatdataEleve[i] } }));
      }

      const res = await Promise.all([...promises]);
      let error = 0;
      res?.forEach((item, index) => {
        if (item.error) {
          error = index + 1;
          // return toast.error("Error l'or de l'ajout de lenfant : ", index + 1);
        }
      });
      if (error) {
        return toast.error("Error l'or de l'ajout de lenfant : ", error);
      }
      toast.success("Creation des enfants reussi");

      if (location?.state?.exist) {
        navigation(-1);
      } else {
        navigation("../select-enfant", { replace: true });
      }
    } catch (error) {
      console.log({ error });
      toast.error("Une erreur c'est produite");
    }
  };

  return (
    <Template
      header={<PageHeader title={"Formulaire des enfants"} />}
      style={{
        backgroundColor: "#fff",
        padding: 10,
      }}
    >
      <div className="flex-1 overflow-auto">
        <p className="text-primary font-bold text-lg">
          Renseigner les informations des enfants
        </p>

        <form action="" className="">
          {fields.map((field, index) => (
            //   <input
            //     key={field.id} // important to include key with field's id
            //     {...register(`enfants.${index}.value`)}
            //   />
            <div className="my-4" key={index}>
              <div className="flex items-center justify-between">
                <p className="p-1 font-bold">Enfant {index + 1}:</p>
                {location?.state?.nombre === "n" ? (
                  <div
                    className="p-2 rounded-full bg-[rgba(255, 0,0,0.5)] cursor-pointer"
                    onClick={() => remove(index)}
                  >
                    <X size={20} color="red" />
                  </div>
                ) : null}
              </div>
              <div className="">
                <div className="flex flex-col border-gray border-2 bg-[#fff] rounded-lg p-2">
                  <label
                    htmlFor="fullname"
                    className="text-sm text-[rgba(0,0,0,0.6)]"
                  >
                    Prenom et nom
                  </label>
                  <input
                    name="fullname"
                    id="fullname"
                    placeholder="ecrire ici..."
                    className="focus:outline-none text-[#000]"
                    type="text"
                    {...register(`enfants.${index}.fullName`, {
                      required: "FullName is required",
                    })}
                  />
                </div>
                {/* {errors?.enfants[index]?.fullName ? (
                  <div className="text-danger text-xs mt-1">
                    {errors?.enfants[index]?.fullName?.message}
                  </div>
                ) : null} */}
              </div>

              <div className="mt-2">
                <div className="flex flex-col border-gray border-2 rounded-lg p-2">
                  <label
                    htmlFor="classe"
                    className="text-sm text-[rgba(0,0,0,0.6)]"
                  >
                    Classe
                  </label>
                  {isLoading
                    ? "chargement des classes..."
                    : classes &&
                      classes?.data && (
                        <select
                          name="classe"
                          id="classe"
                          placeholder="ecrire ici..."
                          className="focus:outline-none p-2 rounded-lg"
                          {...register(`enfants.${index}.classeId`, {
                            required: "Student classe is required",
                          })}
                          defaultValue={field?.classeId.toString()}
                        >
                          <option value={""}>Selectionner une classe</option>
                          {organiserClasses(
                            classes?.data
                              ?.filter(
                                (classe) =>
                                  classe?.name?.toLowerCase()?.trim() !==
                                  "hv3eme"
                              )
                              ?.filter(
                                (classe) =>
                                  classe?.name?.toLowerCase()?.trim() !== "ocs4"
                              )
                          ).map((classe) => (
                            <option
                              value={classe?.id?.toString()}
                              key={classe?.id}
                            >
                              {classe?.name}
                            </option>
                          ))}
                        </select>
                      )}
                </div>
                {errors?.classeId ? (
                  <div className="text-danger text-xs mt-1">
                    {errors?.classeId?.message}
                  </div>
                ) : null}
              </div>

              {fields.length !== index + 1 ? (
                <div className="h-[1px] bg-gray my-4"></div>
              ) : null}
            </div>
          ))}

          {location?.state?.nombre === "n" ? (
            <div
              className="bg-white shadow-lg text-center border-2 rounded-full p-2 mx-6 cursor-pointer hover:bg-gray mt-2"
              onClick={() =>
                append({ classeId: "", fullName: "", id: uuidv4() })
              }
            >
              ajouter un enfant
            </div>
          ) : null}

          <div className="px-0 my-8">
            <button
              className="bg-primary text-white"
              style={{
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                gap: 10,
                padding: "10px",
                // margin: "20px 10px",
                width: "100%",
              }}
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting || fields.length <= 0}
            >
              {isAddLoading ? <Loader className="animate-spin" /> : null}
              Continuer
            </button>
          </div>
        </form>
      </div>
    </Template>
  );
}

export default FormEnfant;
