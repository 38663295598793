import HacFlex from "components/HacFlex";
import HacText from "components/HacText";
import Modal from "components/modal";
import { bgColorPrimary } from "constants";
import useGetUserInfo from "hooks/useGetUserInfo";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetDiagnostiqueQuery } from "redux/features/diagnostiqueApi";
import { clearNote, setNewDiagnostic } from "redux/features/diagnostiqueSlice";
import { useUpdateParentMutation } from "redux/features/parentApi";
import {
  getParentForm,
  setIncreaseCredit,
  setParentForm,
} from "redux/features/parentSlice";
import { ArrowRight, Loader, Plus } from "tabler-icons-react";
import { progressImage } from "utils/images";
function ExerciceListePage() {
  const navigation = useNavigate();
  const params = useParams();
  const location = useLocation();

  const dispatch = useDispatch();

  const formParent = useSelector(getParentForm);
  // const parentForm = useSelector(getParentForm);
  // const credit = useSelector(getParentCredit)?.find(
  //   (item) => item?.phone === formParent?.phone
  // );

  const [UPDATEPARENTCREDIT, { isLoading: updateLoading }] =
    useUpdateParentMutation();

  // console.log({ formParent, credit });

  const { userId } = useGetUserInfo();

  const [show, setShow] = useState(false);
  const [newCreditLoading, setNewCreditLoading] = useState(false);
  const [diagnostique, setDiagnostique] = useState(null);

  const { data, isLoading } = useGetDiagnostiqueQuery({
    classeId: location?.state?.classeId,
    matiereId: location?.state?.matiereId,
  });

  /**
   * Recharger du credit
   */
  const handleSetCredt = async () => {
    setNewCreditLoading(true);
    // setTimeout(() => {
    //   dispatch(setNewParentCredit({ phone: formParent?.phone }));
    //   setNewCreditLoading(false);
    // }, [5000]);
    try {
      const resStatus = await UPDATEPARENTCREDIT({
        parentId: formParent?.id,
        credits: 100,
      });

      if (resStatus?.error) {
        return toast.error("une error est survenu");
      }
      dispatch(
        setParentForm({
          ...formParent,
          credits: 100,
        })
      );
      return toast.success("Credit mise a jour");
    } catch (error) {
      toast.error("une error est survenu");
    } finally {
      setNewCreditLoading(false);
    }
  };

  /**
   *
   */
  const handleStarted = async () => {
    try {
      dispatch(
        setNewDiagnostic({
          diagnosticId: diagnostique?.id,
          studentId: params?.eleveId,
          coachId: userId,
        })
      );

      dispatch(clearNote());
      dispatch(setIncreaseCredit());

      const resStatus = await UPDATEPARENTCREDIT({
        parentId: formParent?.id,
        credits: formParent?.credits - 25,
      });

      // console.log({ resStatus });

      if (resStatus?.data) {
        // la redirection
        dispatch(
          setParentForm({
            ...formParent,
            credits: resStatus?.data?.data?.credits,
          })
        );
        navigation(
          `/${params?.eleveId}/${params?.name}/${
            params.name === "lecture" ? "lecture" : "math"
          }/${diagnostique.id}?niveau=0&epreuve=0`,
          {
            state: {
              eleveId: location?.state?.eleveId,
              randomImage: progressImage[parseInt(Math.random() * 6)],
            },
            replace: true,
          }
        );
      } else {
        toast.error("Une erreur c'est produite");
      }
    } catch (error) {
      toast.error("Une erreur c'est produite");
    }
  };

  return (
    <div>
      <div className="mb-2">
        <span className="font-bold text-lg">Sélectionner un diagnostic.</span>
      </div>

      <Modal
        opened={show}
        onClosed={() => (newCreditLoading ? null : setShow(false))}
      >
        <h1 className="font-bold text-xl text-center">
          Commencer le diagnostic
        </h1>
        {formParent?.credits <= 0 ? (
          <h1 className="font-[500] text-md text-center my-5">
            Il vou reste{" "}
            <span className="text-2xl font-bold text-[green]">
              {formParent?.credits} points
            </span>{" "}
            <br />
            Vous ne pouvez plus continuer
          </h1>
        ) : (
          <h1 className="font-[500] text-md text-center my-5">
            {formParent?.credits < 100
              ? "Il vou reste "
              : "Actuellement vous avez"}{" "}
            <span className="text-2xl font-bold text-[green]">
              {formParent?.credits} points
            </span>{" "}
            et chaque diagnostic commencer on va vous deduire{" "}
            <span className="text-lg font-bold text-[red]">{25} points</span>,
            Si toute fois il vous reste 0 points il faudra envigeager a creer un
            compte ou bien de contacter Genimi
          </h1>
        )}

        {formParent?.credits <= 0 ? (
          <div className="flex items-center gap-5">
            <button
              onClick={handleSetCredt}
              disabled={updateLoading}
              className="animate-bounce flex-1 default:opacity-70 px-2 hover:animate-none bg-primary h-[40px] rounded-full text-[white] mt-6 flex items-center justify-center gap-5"
            >
              {updateLoading ? <Loader className="animate-spin" /> : <Plus />}
              {updateLoading ? "Recharge en cours..." : "Recharger du credit"}
            </button>
          </div>
        ) : (
          <button
            onClick={handleStarted}
            className="animate-bounce px-4 ml-auto flex-1 hover:animate-none bg-primary h-[40px] rounded-full text-[white] mt-6 flex items-center justify-center gap-5"
          >
            <p>Commencer</p>
            {updateLoading ? (
              <Loader className="animate-spin" />
            ) : (
              <ArrowRight />
            )}
          </button>
        )}
      </Modal>

      {isLoading
        ? "Chargement"
        : data?.count > 0
        ? data?.data &&
          data?.data.map((item, index) =>
            index === 0 ? (
              <div
                className="cursor-pointer"
                key={item?.id}
                style={{
                  backgroundColor: "#fff",
                  padding: "1rem",
                  borderRadius: 10,
                  marginBottom: 15,
                  position: "relative",
                }}
                onClick={() => {
                  setDiagnostique(item);
                  setShow(true);
                }}
              >
                <div
                  className=""
                  style={{
                    position: "absolute",
                    width: 5,
                    backgroundColor: bgColorPrimary,
                    height: "60%",
                    borderRadius: 20,
                    left: 0,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                ></div>
                <HacFlex position={"apart"}>
                  <div className="">
                    <HacText>
                      Diagnostic
                      {item?.title} {index + 1}
                    </HacText>
                  </div>
                  <div
                    style={{
                      backgroundColor: bgColorPrimary,
                      borderRadius: 20,
                      height: 20,
                      width: 30,
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <ArrowRight size={18} color="#fff" />
                  </div>
                </HacFlex>
              </div>
            ) : null
          )
        : "Pas d'exercices"}
    </div>
  );
}

export default ExerciceListePage;
