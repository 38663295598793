import { ApiMananger } from "./apiMananger";

const diagnotiqueApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getDiagnostique: build.query({
      query: ({ classeId, matiereId }) =>
        `/diagnostics/?classeId=${classeId}&matiereId=${matiereId}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueByMatierByClasseByNiveau: build.query({
      query: ({ classeId, niveauId, matiereId }) =>
        `/diagnostics/?matiereId=${matiereId}&classeId=${classeId}&niveauId=${niveauId}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueByClasse: build.query({
      query: (classeId) => `/diagnostics/?classeId=${classeId}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueTentavivesByEleves: build.query({
      query: (eleveId) => `/m1/diagnostics/students/${eleveId}/`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueTentavivesByElevesGroupeByTententative: build.query({
      query: ({ eleveId, diagnosticId }) =>
        `/m1/diagnostics/notes/?studentId=${eleveId}&diagnostic=${diagnosticId}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueTentavivesByElevesGroupeByTententativeDetails: build.query({
      query: (tentativeId) =>
        `/m1/diagnostics/tentative/?tentativeId=${tentativeId}`,
      providesTags: ["Diagnostics"],
    }),
    // m1/diagnostics/students/<int:id>/</int:id>
    getDiagnostiqueTentativeByCoach: build.query({
      query: (id) => `/diagnostics/eleves/?coachId=${id}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueTentativeByEleve: build.query({
      query: (id) => `/diagnostics/eleves/?studentId=${id}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueNoteByEleve: build.query({
      query: ({ eleveId, diagnosticId }) =>
        `notes/?eleveId=${eleveId}&epreuveDiagnostic=${diagnosticId}`,
      providesTags: ["Diagnostics"],
    }),
    getEpreuvexByDiagnostique: build.query({
      query: (diagnosticId) =>
        `/diagnostics/parents/?diagnosticId=${diagnosticId}&type_diagnostic=PARENT`,
      providesTags: ["Diagnostics"],
    }),
    getEpreuvexByDiagnostiqueWithNiveau: build.query({
      query: (diagnosticId) => `/epreuves/diagnostic/${diagnosticId}/`,
      providesTags: ["Diagnostics"],
    }),
    getElevesDiagnostiquer: build.query({
      query: ({ diagnosticId, schoolId }) =>
        `/diagnostics/notes/?diagnosticId=${diagnosticId}&schoolId=${schoolId}`,
      providesTags: ["Diagnostics"],
    }),
    getElevesNotesDiagnostiquer: build.query({
      query: (eleveId) => `/notes/?eleveId=${eleveId}`,
      providesTags: ["Diagnostics"],
    }),
    addDiagnostique: build.mutation({
      query({ body, token }) {
        return {
          url: "/notes/",
          method: "POST",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    addDiagnostiqueEleveTentative: build.mutation({
      query({ body, token }) {
        return {
          url: "/diagnostics/eleves/",
          method: "POST",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    updateiagnostiqueEleveTentative: build.mutation({
      query({ body, token }) {
        return {
          url: "/diagnostics/eleves/" + body.id + "/",
          method: "PUT",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
  }),
});

export const {
  useGetDiagnostiqueQuery,
  useAddDiagnostiqueMutation,
  useGetDiagnostiqueByClasseQuery,
  useGetDiagnostiqueByMatierByClasseByNiveauQuery,
  useGetEpreuvexByDiagnostiqueQuery,
  useGetElevesDiagnostiquerQuery,
  useGetElevesNotesDiagnostiquerQuery,
  useAddDiagnostiqueEleveTentativeMutation,
  useUpdateiagnostiqueEleveTentativeMutation,
  useGetDiagnostiqueTentativeByCoachQuery,
  useGetDiagnostiqueTentativeByEleveQuery,
  useGetDiagnostiqueTentavivesByElevesQuery,
  useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery,
  useGetDiagnostiqueTentavivesByElevesGroupeByTententativeDetailsQuery,
  useGetDiagnostiqueNoteByEleveQuery,
  useGetEpreuvexByDiagnostiqueWithNiveauQuery,
} = diagnotiqueApi;
