import Home from "pages/home/page";
import ElevePage from "pages/home/pages/eleve/ElevePage";
import ExerciceListePage from "pages/home/pages/exercice/ExerciceListePage";
import ExerciceLecturePage from "pages/home/pages/exercice/ExerciceLecturePage";
import MatierePage from "pages/home/pages/matiere/MatierePage";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import ExerciceMathPage from "pages/home/pages/exercice/ExerciceMathPage";
import HistoriquePage from "pages/home/pages/historique/HistoriquePage";
import DetailHistoriquePage from "pages/home/pages/historique/pages/DetailHistoriquePage";
import DiagnostiqueHistoriquePage from "pages/home/pages/matiere/pages/DiagnostiqueHistoriquePage";
import AnalysePage from "pages/home/pages/analyse/AnalysePage";
import ComparaisonPage from "pages/home/pages/comparaison/ComparaisonPage";
import ExerciceDetailPage from "pages/home/pages/exerciceDetail/ExerciceDetailPage";
import ContactGenimiPage from "pages/home/pages/contactGenimi/ContactGenimiPage";
import ContactGenimiSuccessPage from "pages/home/pages/contactGenimi/ContactGenimiSuccessPage";
import ParentProspectPage from "pages/home/pages/AuthParent/ParentProspectPage";
import NombreEnfantPage from "pages/home/pages/eleve/forms/NombreEnfantPage";
import FormEnfant from "pages/home/pages/eleve/forms/FormEnfant";
import InfoBeforeDiagnosticPage from "pages/home/pages/eleve/forms/InfoBeforeDiagnosticPage";
import SelectEnfantPage from "pages/home/pages/eleve/forms/SelectEnfantPage";
import OnBoarding from "pages/onboarding/page";
import PayementPage from "pages/home/pages/payement/PayementPage";
import PayementSuccessPage from "pages/home/pages/payement/PayementSuccessPage";
import PayementErrorPage from "pages/home/pages/payement/PayementErrorPage";
import CameraPage from "pages/home/pages/Camera";
import ChatPage from "pages/home/pages/Chat";
import AudioPage from "pages/home/pages/Audio";
import DignostiquePageNew from "pages/home/pages/diagnostic";
import Root from "Root";
import useGetUserInfo from "hooks/useGetUserInfo";

/**
 * Public routes
 */
export const publicRoutes = [
  {
    path: "/welcom",
    element: <OnBoarding />,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
  {
    element: <ParentProspectPage />,
    path: "parentProspect",
  },
  // // {
  // //   path: "/*",
  // //   element: <Navigate to={"/login"} />,
  // // },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
  // {
  //   path: "/register/:phone",
  //   element: <Register />,
  // },
];

/**
 * Private routes
 */
export const privateRoutes = [
  // {
  //   path: "*",
  //   element: <Navigate to={"/"} />,
  // },
  // {
  //   element: <ParentProspectPage />,
  //   path: "parentProspect",
  // },
  // {
  //   element: <Home />,
  //   path: "",
  // },
  {
    element: <ElevePage />,
    path: "eleves",
  },
  {
    element: <Outlet />,
    path: "parentProspect",
    children: [
      { element: <ParentProspectPage />, path: "" },
      {
        element: <NombreEnfantPage />,
        path: "enfant",
      },
      {
        element: <FormEnfant />,
        path: "form",
      },
      {
        element: <InfoBeforeDiagnosticPage />,
        path: "info",
      },
      {
        element: <SelectEnfantPage />,
        path: "select-enfant",
      },
    ],
  },
  {
    element: <Outlet />,
    path: ":eleveId",
    children: [
      {
        element: <MatierePage />,
        path: "matiere",
        children: [
          {
            element: <DiagnostiqueHistoriquePage />,
            path: "",
          },
          {
            element: <Outlet />,
            path: ":name",
            children: [
              {
                element: <ExerciceListePage />,
                path: "",
              },
              // {
              //   element: <ExerciceMathPage />,
              //   path: "math/:id",
              // },
              // {
              //   element: <ExerciceLecturePage />,
              //   path: "lecture/:id",
              // },
            ],
          },
          {
            element: <Outlet />,
            path: "historiques",
            children: [
              { element: <HistoriquePage />, path: ":id" },
              {
                element: <DetailHistoriquePage />,
                path: "detail/:tentativeId",
              },
            ],
          },
        ],
      },
      {
        element: <Outlet />,
        path: ":name",
        children: [
          {
            element: <ExerciceMathPage />,
            path: "math/:id",
          },
          {
            element: <ExerciceLecturePage />,
            path: "lecture/:id",
          },
          {
            element: <AnalysePage />,
            path: "analyse/:id",
          },
          {
            element: <ComparaisonPage />,
            path: "comparaison/:id",
          },
          {
            element: <ExerciceDetailPage />,
            path: "exercice-detail",
          },
          {
            element: <Outlet />,
            path: "contact-genimi",
            children: [
              {
                element: <ContactGenimiPage />,
                path: "",
              },
            ],
          },
          {
            element: <ContactGenimiSuccessPage />,
            path: "contact-genimi/success",
          },
        ],
      },
    ],
  },
  {
    element: <Outlet />,
    path: "contact-genimi",
    children: [
      {
        element: <ContactGenimiPage />,
        path: "",
      },
      {
        element: <ContactGenimiSuccessPage />,
        path: "success",
      },
    ],
  },
  {
    path: "payment",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <PayementPage />,
      },
      {
        path: "success",
        element: <PayementSuccessPage />,
      },
      {
        path: "error",
        element: <PayementErrorPage />,
      },
    ],
  },
  {
    path: "camera",
    element: <CameraPage />,
  },
  {
    path: "chat",
    element: <ChatPage />,
  },
  {
    path: "audio",
    element: <AudioPage />,
  },
  {
    path: "new",
    element: <DignostiquePageNew />,
  },
];

const Routes = () => {
  const { userId, onBoarding } = useGetUserInfo();

  const roots = createBrowserRouter([
    {
      element: <Root />,
      path: "",
      children: [
        // {
        //   path: "",
        //   element: <RequireParent />,
        //   children: [...privateRoutes],
        // },
        {
          element: !onBoarding ? <Navigate to={"/welcom"} /> : <Home />,
          path: "",
        },
        // {
        //   element: <Navigate to={"/"} />,
        //   path: "*",
        // },
        // {
        //   element: <ParentProspectPage />,
        //   path: "parentProspect",
        // },
        ...(userId ? privateRoutes : publicRoutes),
      ],
    },
  ]);

  return <RouterProvider router={roots} />;
};

export default Routes;
