import React from "react";
import PageHeader from "components/PageHeader";
import { Template } from "layouts/Template";
import axios from "axios";
import { ArrowRight } from "tabler-icons-react";
import { getParentForm } from "redux/features/parentSlice";
import { useSelector } from "react-redux";

function PayementPage() {
  const [waveLoading, setWaveLoading] = React.useState(false);

  const parent = useSelector(getParentForm);

  /**
   * // FIXME A faire pour demaine
   * // TODO simplier la gestion des urls
   */
  const handlePayWave = async () => {
    // return alert("lalal");
    try {
      // const response = await axios.get(
      //   `https://genimi-admin.bakeli.tech/payements/checkout/`,
      //   {
      //     headers: {
      //       // Accept: "application/json",
      //       "Content-Type": "application/json",
      //       "X-CSRFToken": csrfToken,
      //     },
      //   }
      // );
      setWaveLoading(true);
      // FIXME a modifier l'or du deploiement
      const baseAppUrl = `${window.location.href
        .split("/")
        ?.splice(0, 3)
        .join("/")}/payment`;
      // const baseAppUrl = "https://genimi-admin.bakeli/";
      const response = await axios.post(
        `https://recette.genimi.io/aapi/wave/checkout`,
        // `https://genimi-admin.bakeli.tech/payements/checkout/`,
        {
          amount: 2000,
          currency: "XOF",
          error_url: `${baseAppUrl}/error`,
          success_url: `${baseAppUrl}/success`,
          client_reference: `${parent?.id}`,
        },
        {
          headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": "",
          },
        }
      );

      // console.log({ response });

      if (response.data) {
        const deserialize = JSON.parse(response.data);
        const _url = deserialize.wave_launch_url;
        console.log({ _url, deserialize });
        window.open(_url, "_blank");
      } else {
        alert("Error l'or du paiement !");
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setWaveLoading(false);
    }
  };

  return (
    <Template
      header={<PageHeader title={"Paiement"} />}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 20,
        padding: 10,
      }}
    >
      {/* <Button
        title={"Payer par Wave"}
        style={{ marginTop: 20 }}
        bgColor={"#1AB3E5"}
        color={"#fff"}
        onClick={handlePayWave}
        loading={waveLoading}
      /> */}
      <div className="flex justify-center">
        <img
          src={"https://www.wave.com/img/nav-logo.png"}
          height={45}
          alt="wave"
        />
      </div>
      <button
        className="flex items-center justify-center cursor-pointer gap-5 text-white p-3 my-4 bg-[#1AB3E5] rounded-full mx-3"
        data-testid="cypress-welcom-button"
        onClick={handlePayWave}
        disabled={waveLoading}
      >
        Valider le paiement des 2000 FCFA
        <ArrowRight color="#fff" />
      </button>
      {/* <div style={{ padding: "10px 7px" }}>
          {waveUrl && (
          <iframe
            key={1}
            title={"Wave paiement"}
            src={
              waveUrl ??
              "https://pay.wave.com/c/cos-1e3tydh3g113t?a=2000&c=XOF&m=Genimi"
            }
            width={"100%"}
            height={300}
            style={{ borderRadius: 10, border: "none" }}
          />
          )}
        </div> */}
    </Template>
  );
}

export default PayementPage;
