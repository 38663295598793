import React, { useState } from "react";

const keys = [
  ["a", "z", "e", "r", "t", "y", "u", "i", "o", "p"],
  ["q", "s", "d", "f", "g", "h", "j", "k", "l", "m"],
  ["w", "x", "c", "v", "b", "n"],
  ["Effacer"],
];

function KeyBoard({ onPress, selected, canClear }) {
  const [maj, setMaj] = useState(false);
  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.08)",
        flexDirection: "column",
        display: "flex",
        gap: 5,
        padding: 10,
        borderRadius: 10,
      }}
    >
      {keys.map((section, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-around",
            gap: 2,
          }}
        >
          {section.map((lettre) => (
            <div
              key={lettre}
              style={{
                backgroundColor: "#fff",
                flexGrow: 1,
                display: !canClear && lettre === "Effacer" ? "none" : "grid",
                placeItems: "center",
                height: "2rem",
                width: lettre === "Maj" ? 70 : "1rem",
                borderRadius: 8,
                fontWeight: "bold",
                fontSize: 22,
                opacity: selected?.includes(lettre) ? 0.4 : 1,
                textTransform: maj ? "uppercase" : "lowercase",
              }}
              className="shadow-lg cursor-pointer scale-95"
              onClick={() =>
                onPress
                  ? lettre === "Effacer"
                    ? onPress("")
                    : lettre === "Maj"
                    ? setMaj(!maj)
                    : onPress(lettre)
                  : null
              }
            >
              {lettre}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default KeyBoard;
