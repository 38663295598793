export const Data = {
  lecture: [
    {
      id: 1,
      exercice: "Reorganiser les phrases",
      data: [
        {
          question: "Correspondre les mots",
          consign: "papa - chose, mouton - personne, ardoise - animal",
          correction: "papa - personne, mouton - animal, ardoise - chose",
          type: 2,
        },
        {
          question: "Reorganiser cette phrase correctement",
          consign: "va papa maison a la soir ce",
          correction: "papa va a la maison ce soir",
          type: 1,
        },
        {
          question: "Reorganiser cette phrase correctement",
          consign: "ici viens veux quand tu",
          correction: "viens ici quand tu veux",
          type: 1,
        },
        {
          question: "Reorganiser cette phrase correctement",
          consign: "avoir sortira manger apres Mouhamed",
          correction: "Mouhamed sortira apres avoir manger",
          type: 1,
        },
      ],
    },
  ],
  mathematique: [
    {
      id: 2,
      exercice: "Trouver l'inconnu",
      data: [
        {
          question: "2 + ? + 3 = 7",
          answer: "2",
          choices: [1, 3, 0, 2, 9],
        },
        {
          question: "? - 4 = 0",
          answer: "4",
          choices: [2, 4, 6, 8, 10],
        },
        {
          question: "7 - ? = 7",
          answer: "0",
          choices: [1, 0, 9],
        },
        {
          question: "? + 4 = 10",
          answer: "6",
          choices: [2, 4, 6, 8, 0],
        },
        {
          question: "? - 3 = 7",
          answer: "10",
          choices: [10, 5, 2, 9],
        },
      ],
    },
    {
      id: 3,
      exercice: "Ranger les nombres du plus grand au plus petit",
      data: [],
    },
  ],
};
