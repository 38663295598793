import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { baseApi } from "redux/features/apiMananger";
import diagnostiqueReducer from "redux/features/diagnostiqueSlice";
import parentReducer from "redux/features/parentSlice";
import userReducer from "redux/features/userSlice";
import eleveReducer from "redux/features/eleveSlice";

const persistConfig = {
  key: "root-genimi-parent",
  storage,
};

const rootReducer = combineReducers({
  user: persistReducer(
    persistConfig,
    combineReducers({
      userReducer,
      diagnostiqueReducer,
      parentReducer,
      eleveReducer,
    })
  ),
  // diagnostique: persistReducer(persistConfig, diagnostiqueReducer),
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([baseApi.middleware]);
  },
  devTools: true,
});

export const persistor = persistStore(store);
