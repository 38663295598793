import React from "react";

function MatiereCard({ onClick, name, img }) {
  return (
    <div
      style={{
        flex: 1,
        borderRadius: 10,
        backgroundColor: "rgba(0,0,0,0.08)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
        padding: "15px 10px",
      }}
      onClick={onClick}
      className="cursor-pointer"
    >
      <div
        className=""
        style={{
          width: 50,
          height: 50,
          borderRadius: 45,
          backgroundColor: "#fff",
          display: "flex",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <img src={`/icons/${img}.png`} alt="math" style={{ width: "40%" }} />
      </div>
      <h5 style={{ fontSize: ".8rem" }}>{name}</h5>
    </div>
  );
}

export default MatiereCard;
