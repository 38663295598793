import { useSelector } from "react-redux";
import { getStudent } from "redux/features/userSlice";

function useGetStudent() {
  const student = useSelector(getStudent);

  return {
    id: student?.id,
    firstName: student?.firstName,
    lastName: student?.lastName,
    classe: { ...student?.classe },
    school: { ...student?.school },
  };
}

export default useGetStudent;
